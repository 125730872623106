import { useAuth0 } from '@auth0/auth0-react';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import LabelIcon from '@mui/icons-material/Label';
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import { setPatients } from '../../state/patientSlice';
import { selectUserType } from '../../state/userSlice';
import { USER_ROLES } from '../../utils/constants/system';

export default function CollapsibleRow(props) {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const { index, patient, patients, setErrors } = props;
  const userType = useSelector(selectUserType);
  const [open, setOpen] = useState(false);
  const [initialNote, setInitialNote] = useState(patient.notes);

  const checkInCall = (patient) => {
    const highlight = patient.icDate ? 'gray' : 'red';
    return (
      <div className="need-check-in">
        <div className={`date ${highlight}`}>Needs Date</div>
        <p>{`${patient.checkinCallCountDown}`}</p>
      </div>
    );
  };

  const classNames = (...classes) => classes.filter(Boolean).join(' ');

  const followupDate = (patient) => {
    const highlight = patient.appointment?.date === null ? 'red' : 'gray';
    return <div className={`date ${highlight}`}>Needs Date</div>;
  };

  const formStatus = (s) => {
    const status = s.slice(0, 6);
    return status[0].toUpperCase() + status.substring(1);
  };

  const missingDataEntry = <div className="date red">Needs Date</div>;

  const updatePatientTag = async () => {
    const patientsCopy = patients.slice();
    const patient = patientsCopy[index];

    patientsCopy[index] = {
      ...patient,
      tag: patient.tag ? '' : 'saved',
    };

    try {
      const token = await getAccessTokenSilently();

      await BaymaxApi.updatePatient(patient.id, { tag: patientsCopy[index].tag }, token);
      dispatch(setPatients(patientsCopy));
    } catch (err) {
      setErrors(err);
    }
  };

  const updateNote = (event) => {
    const rows = patients.slice();

    rows[index] = {
      ...rows[index],
      notes: event.target.value,
    };

    dispatch(setPatients(rows));
  };

  const saveEdit = async () => {
    const token = await getAccessTokenSilently();
    const { id, notes } = patients[index];

    try {
      await BaymaxApi.updatePatient(id, { notes }, token);

      setOpen(false);
      setInitialNote(notes);
    } catch (err) {
      setErrors(err);
    }
  };

  const cancelEdit = () => {
    const rows = patients.slice();

    rows[index] = {
      ...rows[index],
      notes: initialNote,
    };

    dispatch(setPatients(rows));
    setOpen(false);
  };

  return (
    <React.Fragment key={props.id}>
      <TableRow>
        <TableCell align="center" className={`sticky patient-name ${patient.tag}`}>
          <div className="inner">
            <span>{props.index + 1}</span>
            {patient.tag ? (
              <LabelIcon onClick={updatePatientTag} data-id={patient.id} />
            ) : (
              <LabelOutlinedIcon onClick={updatePatientTag} data-id={patient.id} />
            )}
            <Link to={`/patients/${patient.id}`} state={{ id: `${patient.id}` }}>
              {patient.lastName}, {patient.firstName}
            </Link>
          </div>
        </TableCell>
        <TableCell align="center" className={classNames('sticky second-col', patient.tag)}>
          {patient.dischargeDate ? dayjs(patient.dischargeDate).format('M/D/YYYY') : missingDataEntry}
        </TableCell>
        <TableCell align="center" className={patient.tag}>
          {patient.icDate ? dayjs(patient.icDate).format('M/D/YYYY') : checkInCall(patient)}
        </TableCell>
        <TableCell align="center" className={patient.tag}>
          <Link to={`/patients/${patient.id}/interactive`} state={{ id: `${patient.id}` }}>
            {formStatus(patient.icForm.status)}
          </Link>
        </TableCell>
        <TableCell align="center" className={patient.tag}>
          {patient.followupDate ? dayjs(patient.followupDate).format('M/D/YYYY') : followupDate(patient)}
        </TableCell>
        {userType === USER_ROLES.ADMIN ? (
          <TableCell align="center" className={patient.tag}>
            {patient.billedDate ? dayjs(patient.billedDate).format('M/D/YYYY') : missingDataEntry}
          </TableCell>
        ) : null}
        {userType === USER_ROLES.ADMIN ? (
          <TableCell className={classNames('notes', patient.tag)}>
            <Tooltip
              title={
                <div
                  style={{
                    whiteSpace: 'pre-line',
                    fontSize: '14px',
                  }}
                >
                  {patient.notes}
                </div>
              }
              placement="bottom"
              arrow
            >
              <TextSnippetOutlinedIcon
                sx={{ color: patient.notes?.length ? 'black' : 'gray' }}
                onClick={() => setOpen(!open)}
              />
            </Tooltip>
          </TableCell>
        ) : null}
      </TableRow>
      <TableRow className="collapse">
        <TableCell colSpan={8}>
          <Collapse in={open}>
            <Box sx={{ margin: 1 }}>
              <TextField multiline rows={3} value={patient.notes} onChange={updateNote} />
              <div className="actions">
                <CheckIcon className="check" onClick={saveEdit} />
                <CloseIcon className="close" onClick={cancelEdit} />
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
