import './style.css';

import { useAuth0 } from '@auth0/auth0-react';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import dayjs from 'dayjs';
import { find, isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import BaymaxApi from '../../BaymaxApi';
import DateInput from '../../components/DateInput';
import Loader from '../../components/Loader';
import { addPatientCaseId, selectPatientProfiles, setPatientProfile } from '../../state/patientSlice';
import { selectProviders, selectProvidersWithoutUnassigned } from '../../state/systemSlice';
import { selectUserType } from '../../state/userSlice';
import {
  PATIENT_AUDIT_HISTORY_TYPES,
  PATIENT_BILLING_CODES,
  PATIENT_LABEL_OPTIONS,
} from '../../utils/constants/patient';
import { USER_ROLES } from '../../utils/constants/system';
import { phoneFormat } from '../../utils/helpers';
import AuditHistory from '../InteractiveForm/AuditHistory';
import Notification from '../Notification/component';

export default function PatientProfile() {
  const dispatch = useDispatch();
  const { getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const navigate = useNavigate();
  const prefetchedPatients = useSelector(selectPatientProfiles);
  const userType = useSelector(selectUserType);
  const providers = useSelector(selectProviders);
  const providersWithoutUnassigned = useSelector(selectProvidersWithoutUnassigned);
  const [errors, setErrors] = useState([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [billingWarningText, setBillingWarningText] = useState('');
  const [isNewPatient, setIsNewPatient] = useState(true);
  const [isDirty, setIsDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [patient, setPatient] = useState({
    appointment: {
      date: '',
      startTime: '',
    },
    athenaId: null,
    athenaProviderId: null,
    billedDate: '',
    billingCode: null,
    dateOfBirth: '',
    dischargeDate: '',
    firstName: '',
    id: null,
    isActive: null,
    lastName: '',
    alternativePhone: '',
    practiceId: '',
    phone: '',
    providerId: '',
    tcmProviderId: '',
    icDate: '',
    followupDate: '',
    label: '',
    notes: '',
    caseId: null,
    tcmDeadline: '',
  });
  const [patientCopy, setPatientCopy] = useState(patient);

  const getPatientFromDb = async (id) => {
    const token = await getAccessTokenSilently();
    return BaymaxApi.getPatient(id, token);
  };

  const id = location.state && location.state.id ? location.state.id : location.pathname.split('/').pop();

  useEffect(() => {
    const fetchPatient = async () => {
      const saveSuccess = location.state?.showSuccess;
      let patientToStore = prefetchedPatients[id];

      if (saveSuccess || !patientToStore) {
        patientToStore = await getPatientFromDb(id);
      }

      if (!prefetchedPatients[id]) {
        dispatch(setPatientProfile(patientToStore));
      }
      setPatient(patientToStore);
      setPatientCopy(patientToStore);
      setLoading(false);
    };

    if (id && id !== 'new') {
      fetchPatient();
      setIsNewPatient(false);
    }

    if (location.state && location.state.searchValues) {
      setPatient({
        ...patient,
        athenaId: location.state.athenaId,
        firstName: location.state.searchValues.firstName,
        lastName: location.state.searchValues.lastName,
        dateOfBirth: location.state.searchValues.dob,
        providerId: location.state.searchValues.pcp || 1,
        phone: location.state?.ingestionData?.phone || '',
        dischargeDate: location.state?.ingestionData?.dischargeDate || '',
      });
      setLoading(false);
    }

    if (location.state && location.state.patient) {
      const dischargeDate = location.state?.ingestionData?.dischargeDate;
      const patientInfo = { ...location.state.patient };

      if (!find(providers, ['id', patientInfo.providerId])) {
        patientInfo.providerId = null;
      }

      setPatient({
        ...patient,
        ...patientInfo,
        providerId: patientInfo.providerId,
        dischargeDate,
        notes: meditechIdAndPhoneNote(),
      });
      setLoading(false);
    }

    if (location.state && location.state.uploadedPatient) {
      const newPatient = location.state.uploadedPatient;
      const nameSeparatorIndex = newPatient.name.indexOf(',');

      setPatient({
        ...patient,
        firstName: newPatient.name.slice(nameSeparatorIndex + 2, newPatient.name.length),
        lastName: newPatient.name.slice(0, nameSeparatorIndex),
        dischargeDate: newPatient.dischargeDate,
        dateOfBirth: newPatient.dob,
        phone: newPatient.phoneNumber,
      });
      setLoading(false);
    }

    if (location.state && location.state.showSuccess) {
      setShowSuccess(location.state.showSuccess);
      navigate(location.pathname, { replace: true });
    }
  }, [location.state]);

  const meditechIdAndPhoneNote = () => {
    if (!isEmpty(location.state?.ingestionData)) {
      const data = location.state.ingestionData;

      return `Phone: ${data.phone}\nEHR ID: ${data.meditechId}`;
    }

    return '';
  };

  const cleanPhoneNumber = (input) => {
    if (input) {
      return input.replace(/[^0-9.]/g, '');
    }
    return '';
  };

  const save = async (stayOnPage) => {
    const token = await getAccessTokenSilently();

    setLoading(true);

    try {
      if (isNewPatient) {
        const obj = {
          athenaId: patient.athenaId,
          providerId: patient.providerId,
          tcmProviderId: patient.tcmProviderId,
          firstName: patient.firstName,
          lastName: patient.lastName,
          dateOfBirth: dayjs(patient.dateOfBirth).format('MM/DD/YYYY'),
          dischargeDate: patient.dischargeDate,
          phone: cleanPhoneNumber(patient.phone),
          alternativePhone: cleanPhoneNumber(patient.alternativePhone),
          billedDate: patient.billedDate,
          billingCode: patient.billingCode === 'None' ? null : patient.billingCode,
          icDate: patient.icDate,
          practiceId: patient.practiceId,
          followupDate: patient.followupDate,
          label: patient.label === 'None' ? '' : patient.label,
          isUnassigned: patient.unassigned,
          notes: patient.notes,
          facilityId: patient?.facility?.id,
          tcmDeadline: patient.tcmDeadline,
        };

        if (location.state.ingestionData) {
          const { ingestionId, patientId } = location.state.ingestionData;

          obj.ingestionId = parseInt(ingestionId, 10);
          obj.ingestionLineageId = parseInt(patientId, 10);
        }

        const newPatient = await BaymaxApi.createPatient(obj, token);

        setPatient(newPatient);
        dispatch(setPatientProfile(newPatient));

        if (stayOnPage) {
          window.history.replaceState(null, null, `/patients/${newPatient.id}`);
          setIsNewPatient(false);
        }
      }

      if (!isNewPatient && isDirty) {
        const obj = {
          id: patient.id,
          firstName: patient.firstName,
          lastName: patient.lastName,
          dateOfBirth: dayjs(patient.dateOfBirth).format('MM/DD/YYYY'),
          dischargeDate: patient.dischargeDate,
          providerId: patient.providerId,
          tcmProviderId: patient.tcmProviderId,
          phone: cleanPhoneNumber(patient.phone),
          alternativePhone: cleanPhoneNumber(patient.alternativePhone),
          billedDate: patient.billedDate,
          billingCode: patient.billingCode === 'None' ? null : patient.billingCode,
          followupDate: patient.followupDate,
          label: patient.label === 'None' ? '' : patient.label,
          notes: patient.notes,
          athenaId: patient.athenaId,
          tcmDeadline: patient.tcmDeadline,
        };

        await BaymaxApi.updatePatient(patient.id, obj, token);
        dispatch(setPatientProfile(obj));
      }

      if (location.state?.ingestionData?.ingestionId && !stayOnPage) {
        navigate('/patients/upload/processed', {
          state: { uploadId: location.state.ingestionData.ingestionId },
        });
      } else if (stayOnPage) {
        setIsDirty(false);
        setLoading(false);
        setShowSuccess(true);
        window.scrollTo(0, 0);
      } else {
        navigate('/dashboard', { state: { showSuccess: true } });
      }
    } catch (err) {
      setLoading(false);
      setErrors(err);
    }
  };

  const checkIsDirty = (prev, next) => {
    if (prev !== next) {
      setIsDirty(true);
    } else {
      setIsDirty(false);
    }
  };

  const createPatientCase = async () => {
    try {
      const token = await getAccessTokenSilently();
      const patientCase = await BaymaxApi.createPatientCase(patient.id, token);

      patientCase.providerId = patient.tcmProviderId;

      dispatch(addPatientCaseId({ id: patient.id, caseId: patientCase.id }));
      navigate(`case/${patientCase.id}`, { state: { patientCase } });
    } catch (error) {
      setErrors(error);
    }
  };

  const handleDateChange = (value, name) => {
    if (value && value.format() !== 'Invalid Date') {
      setPatient({
        ...patient,
        [name]: value.format('MM/DD/YYYY'),
      });

      checkIsDirty(patientCopy[name], value.format('MM/DD/YYYY'));
    } else {
      setPatient({
        ...patient,
        [name]: '',
      });

      checkIsDirty(patientCopy[name], value);
    }
  };

  const clearDate = (name, value) => {
    setPatient({
      ...patient,
      [name]: '',
    });

    checkIsDirty(patientCopy[name], value);
  };

  const goToInvoice = () => {
    const { invoiceId } = patient.invoiceDetails;

    navigate(`/invoicing/${invoiceId}`, { state: { invoiceId } });
  };

  const checkBillingWindow = (billingCode) => {
    const dischargeDate = dayjs(patient.dischargeDate);
    const followupDate = dayjs(patient.followupDate);
    const daysDiff = followupDate.diff(dischargeDate, 'day');

    if (billingCode === '99496' && daysDiff > 7) {
      setBillingWarningText(
        `99496 billed more than 7 days after discharge. The selected date is ${daysDiff} days post discharge.`
      );
    } else if (billingCode === '99495' && daysDiff > 14) {
      setBillingWarningText(
        `99495 billed more than 14 days after discharge. The selected date is ${daysDiff} days post discharge.`
      );
    } else {
      setBillingWarningText('');
    }
  };

  return (
    <Container className="patient-profile">
      <Notification errors={errors} showSuccess={showSuccess} setShowSuccess={setShowSuccess} />
      <div className="header">
        <h2>Patient Information</h2>

        {patient.caseId ? (
          <Link to={`/patients/${patient.id}/case/${patient.caseId}`} state={{ caseId: patient.caseId }}>
            <Button>View Case</Button>
          </Link>
        ) : (
          <Button onClick={createPatientCase}>Create New Case</Button>
        )}
      </div>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <div className="row">
            <div className="input">
              <p>Last Name</p>
              <TextField
                id="lastName"
                name="lastName"
                error={userType === USER_ROLES.ADMIN && !patient.lastName.length}
                value={patient.lastName}
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    lastName: e.target.value,
                  });
                  checkIsDirty(patientCopy.lastName, e.target.value);
                }}
              />
            </div>
            <div className="input">
              <p>First Name</p>
              <TextField
                id="firstName"
                name="firstName"
                error={userType === USER_ROLES.ADMIN && !patient.firstName.length}
                value={patient.firstName}
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    firstName: e.target.value,
                  });
                  checkIsDirty(patientCopy.firstName, e.target.value);
                }}
              />
            </div>
            <div className="input date">
              <p>Date of Birth</p>
              <DateInput
                name="dateOfBirth"
                value={patient.dateOfBirth}
                handleChange={handleDateChange}
                clearDate={clearDate}
                disableFuture
                hideTime
              />
            </div>
          </div>
          <div className="row">
            <div className="input">
              <p>Athena ID</p>
              <TextField id="athenaId" name="athenaId" value={patient.athenaId || ''} disabled />
            </div>
            <div className="input">
              <p>Primary Phone Number</p>
              <TextField
                id="phoneNumber"
                name="phoneNumber"
                error={userType === USER_ROLES.ADMIN && !patient.phone.length}
                value={phoneFormat(patient.phone)}
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    phone: phoneFormat(e.target.value),
                  });
                  checkIsDirty(patientCopy.phone, e.target.value);
                }}
              />
            </div>
            <div className="input">
              <p>Secondary Phone Number</p>
              <TextField
                id="alternativePhone"
                name="alternativePhone"
                value={phoneFormat(patient.alternativePhone)}
                onChange={(e) => {
                  setPatient({
                    ...patient,
                    alternativePhone: phoneFormat(e.target.value),
                  });
                  checkIsDirty(patientCopy.alternativePhone, e.target.value);
                }}
              />
            </div>
          </div>
          <div className="row">
            <div className="input">
              <p>PCP Provider</p>
              <FormControl error={userType === USER_ROLES.ADMIN && !patient.providerId}>
                <Select
                  value={patient.providerId || ''}
                  onChange={(e) => {
                    setPatient({
                      ...patient,
                      providerId: e.target.value,
                    });
                    checkIsDirty(patientCopy.providerId, e.target.value);
                  }}
                  className="filter-select"
                >
                  {providers.map((provider) => (
                    <MenuItem key={provider.id} value={provider.id}>
                      {provider.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="input">
              <p>TCM Provider</p>
              <FormControl error={!patient.tcmProviderId}>
                <Select
                  value={patient.tcmProviderId || ''}
                  onChange={(e) => {
                    setPatient({
                      ...patient,
                      tcmProviderId: e.target.value,
                    });
                    checkIsDirty(patientCopy.tcmProviderId, e.target.value);
                  }}
                  className="filter-select"
                >
                  {providersWithoutUnassigned.map((provider) => (
                    <MenuItem key={provider.id} value={provider.id}>
                      {provider.displayName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="input">
              <p>Patient Label</p>
              <FormControl>
                <Select
                  value={patient.label || 'None'}
                  onChange={(e) => {
                    setPatient({
                      ...patient,
                      label: e.target.value,
                    });
                    checkIsDirty(patientCopy.label, e.target.value);
                  }}
                  className="filter-select"
                >
                  {Object.entries(PATIENT_LABEL_OPTIONS).map(([k, v]) => (
                    <MenuItem value={k} key={k}>
                      {v}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="row">
            <div className="input date">
              <p>Discharge Date</p>
              <DateInput
                name="dischargeDate"
                value={patient.dischargeDate}
                handleChange={handleDateChange}
                clearDate={clearDate}
                hideTime
                error={userType === USER_ROLES.ADMIN && !patient.dischargeDate}
              />
            </div>
            <div className="input date">
              <p>Last Date for TCM</p>
              <DateInput name="tcmDeadline" value={patient.tcmDeadline} hideTime disabled />
            </div>
            <div className="input" />
          </div>
          <div className="interactive">
            <Link
              to={`/patients/${patient.id}/interactive`}
              state={{ id: `${patient.id}` }}
              className={isNewPatient ? 'disabled' : ''}
            >
              <Button disabled={isNewPatient}>Interactive Component Form</Button>
            </Link>
          </div>
          <div className="notes">
            <TextField
              label="Internal Notes"
              multiline
              rows={8}
              value={patient.notes}
              onChange={(e) => {
                setPatient({
                  ...patient,
                  notes: e.target.value,
                });
                checkIsDirty(patientCopy.notes, e.target.value);
              }}
            />
          </div>
          <div className="row">
            <div className="input date">
              <p>Actual Follow-up</p>
              <DateInput
                name="followupDate"
                value={patient.followupDate}
                handleChange={handleDateChange}
                clearDate={clearDate}
                hideTime
              />
            </div>
            <div className="input date">
              <p>Billing Date</p>
              <DateInput
                name="billedDate"
                value={patient.billedDate}
                handleChange={handleDateChange}
                clearDate={clearDate}
                disableFuture
                hideTime
              />
            </div>
            <div className="input">
              <p>Billing Code</p>
              <FormControl>
                <Select
                  value={patient.billingCode || 'None'}
                  onChange={(e) => {
                    setPatient({
                      ...patient,
                      billingCode: e.target.value,
                    });
                    checkBillingWindow(e.target.value);
                    checkIsDirty(patientCopy.billingCode ?? '', e.target.value);
                  }}
                  className="filter-select"
                >
                  {PATIENT_BILLING_CODES.map((code) => (
                    <MenuItem value={code} key={code}>
                      {code}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{billingWarningText}</FormHelperText>
              </FormControl>
            </div>
          </div>

          {!isEmpty(patient.invoiceDetails) ? (
            <div className="row">
              <div className="input invoice" onClick={goToInvoice}>
                <p>Invoice Number</p>
                <TextField
                  value={patient.invoiceDetails.invoiceNumber}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </div>
            </div>
          ) : null}

          <div className="save">
            <Button variant="contained" onClick={() => save(true)} disabled={!isDirty}>
              Save
            </Button>
            <Button variant="contained" onClick={() => save(false)} disabled={!isDirty}>
              Finish
            </Button>
          </div>

          <AuditHistory patientId={patient.id} type={PATIENT_AUDIT_HISTORY_TYPES.PROFILE} />
        </div>
      )}
    </Container>
  );
}
