import { createSlice } from '@reduxjs/toolkit';

import { USER_ROLES } from '../utils/constants/system';

const initialState = {
  orgName: '',
  userName: '',
  userType: USER_ROLES.COORDINATOR,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setOrgName: (state, action) => {
      state.orgName = action.payload;
    },
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    resetUserState: (state) => {
      state = initialState;
    },
  },
});

export const { setOrgName, setUserName, setUserType, resetUserState } = userSlice.actions;

export default userSlice.reducer;

export const selectOrgName = (state) => state.user.orgName;
export const selectUserName = (state) => state.user.userName;
export const selectUserType = (state) => state.user.userType;
